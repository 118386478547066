import React from 'react';
import imageLogo from '../../assets/img/logo.png';
import imageDivider from '../../assets/img/news/ornament2.png';
import './styles.css';

const Terms = () => {
  return (
    <div className="page-content-full download-page">
      <div className="page-content-header">
        <div className="logo">
          <img src={imageLogo} alt="Saint Tale"></img>
        </div>
        <h1>Terms of Service</h1>
        <div className="divider">
          <img src={imageDivider} alt=""></img>
        </div>
      </div>

      <div className="page-content">
        <div className="page-content-title">Terms of Service</div>
        <div className="info-text">
          {/* prettier-ignore-start */}
          <h1>Terms of Use</h1>
          <p>
            By accessing or using www.sainttale.com (the &quot;site&quot;) and
            its services (the &quot;services&quot;), you (the &quot;user&quot;)
            agree to comply with the terms and conditions governing the
            user&lsquo;s use of any areas of the site and affiliated services as
            set forth below.
          </p>

          <h1>Use of Site</h1>
          <p>
            This site or any portion of the site as well as the services may not
            be reproduced, duplicated, copied, sold, resold, or otherwise
            exploited for any commercial purpose except as expressly permitted
            by Saint Tale. Saint Tale World reserves the right to refuse service
            in its discretion, without limitation, if Saint Tale believes the
            user conduct violates applicable law or is harmful to the interests
            of Saint Tale, other users of the site and the services or its
            affiliates.
          </p>

          <h1>Use of Services</h1>
          <p>
            Saint Tale reserves all rights in and to the Materials not expressly
            granted herein. Subject to Saint Tale&lsquo;s policies governing the
            use of its intellectual property, as applicable, you may not (i) use
            or transmit any Materials on or to any other Web site or network;
            (ii) modify, translate, reverse engineer, decompile, disassemble
            decompile, disassemble or create derivative works based on any
            Materials; (iii) reproduce any Materials other than as specified
            above; (iv) distribute, license, rent, sell, lease or otherwise
            transfer any Materials; or (v) remove, obscure or alter any notice
            of copyright or other proprietary notices present on or in any
            Materials. By using the site or the services, the user agrees to
            take responsibility to follow game’s Eula.
          </p>

          <h1>Distribution</h1>
          <p>
            sainttale.com does not support distribution of any game clients.
            download links on the site constitute mirrors of external sites for
            educational purposes.
          </p>

          <h1>Site Account</h1>
          <p>
            The user may register a regular account and password for the service
            for free. you are responsible for all activity under your account,
            associated accounts, and passwords. the site is not responsible for
            unauthorized access to your account, and any loss of virtual items
            associated with it.
          </p>

          <h1>Events, Videos and Community Creations</h1>
          <p>
            Upon registering an account, you agree that your account or
            characters may be included in any events, streams or videos for
            promotion or review purposes.
          </p>

          <h1>Access to the Site and Services</h1>
          <p>
            Saint Tale provides free and unlimited access to the site and the
            services.
          </p>

          <h1>Submission</h1>
          <p>
            Saint Tale does not assume any obligation with respect to any
            submission and no confidential or fiduciary understanding or
            relationship is established by the site&lsquo;s receipt or
            acceptance of any submission. all submissions become the exclusive
            property of the site and its affiliates. the site and its affiliates
            may use any submission without restriction and the user shall not be
            entitled to any compensation.
          </p>

          <h1>Third-Party Content</h1>
          <p>
            Neither Saint Tale, nor its affiliates, nor any of their respective
            officers, directors, employees, or agents, nor any third party
            including any provider/affiliate, or any other user of the site and
            services, guarantees the accuracy, completeness, or usefulness of
            any content, nor its merchantability or fitness for any particular
            purpose. in some instances, the content available through the site
            may represent the opinions and judgments of providers/affiliates or
            users. Saint Tale and its affiliates do not not endorse and shall
            not be responsible for the accuracy or reliability of any opinion,
            advice, or statement made on the site and the services by anyone
            other than authorized Saint Tale employees. under no circumstances
            shall Saint Tale, or its affiliates, or any of their respective
            officers, directors, employees, or agents be liable for any loss,
            damage or harm caused by a user&lsquo;s reliance on information
            obtained through the site and the services. it is the responsibility
            of the user to evaluate the information, opinion, advice, or other
            content available through this site.
          </p>

          <h1>Disclaimers and Limitation of Liability</h1>
          <p>
            User agrees that use of the site and the services is at the
            user&lsquo;s sole risk. neither Saint Tale, nor its affiliates, nor
            any of their respective officers, directors, employees, agents,
            third-party content providers, merchants, sponsors, licensors or the
            like (collectively, &quot;providers&quot;), warrant that the site
            and the services will be uninterrupted or error-free; nor do they
            make any warranty as to the results that may be obtained from the
            use of the site and the services, or as to the accuracy,
            reliability, or currency of any information content, service, or
            merchandise provided through this site. the site and the services
            are provided by Saint Tale on an &quot;as is&quot; and &quot;as
            available&quot; basis. the site makes no representations or
            warranties of any kind, express or implied, as to the operation of
            the site, the information, content, materials or products, included
            on this site. to the full extent permissible by applicable law, the
            site disclaims all warranties, express or implied, including but not
            limited to, implied warranties of merchantability and fitness for a
            particular purpose. Saint Tale will not be liable for any damages of
            any kind arising from the use of the site and the services,
            including but not limited to direct, indirect, incidental, punitive
            and consequential damages. under no circumstances shall Saint Tale
            or any other party involved in creating, producing, or distributing
            the site and the services be liable for any direct, indirect,
            incidental, special, or consequential damages that result from the
            use of or inability to use the site and the services, including but
            not limited to reliance by the user on any information obtained from
            the site or that result from mistakes, omissions, interruptions,
            deletion of files or email, errors, defects, viruses, delays in
            operation or transmission, or any failure of performance, whether or
            not resulting from acts of god, communications failure, theft,
            destruction, or unauthorized access to the site&lsquo;s records,
            programs, or services. the user hereby acknowledges that these
            disclaimers and limitation on liability shall apply to all content,
            merchandise, and services available through the site and the
            services. in states that do not allow the exclusion of limitation or
            limitation of liability for consequential or incidental damages, the
            user agrees that liability in such states shall be limited to the
            fullest extent permitted by applicable law.
          </p>

          <h1>Termination of Service</h1>
          <p>
            Saint Tale reserves the right, in its sole discretion, to change,
            suspend, limit, or discontinue any aspect of the service and the
            services at any time. Saint Tale may suspend or terminate any
            user&lsquo;s access to all or part of the site and the services,
            without notice, for any conduct that Saint Tale, in its sole
            discretion, believes is in violation of these terms and conditions.
          </p>

          <h1>Payments</h1>
          <p>
            Payments are made on a voluntary basis in appreciation of the site
            and the services. Saint Tale does not assume any financial
            obligation with respect to payments. the user must be over 18 to be
            eligible to make a payment to Saint Tale. Saint Tale is not liable
            for any unauthorized payments.
          </p>

          <h1>Acknowledgement</h1>
          <p>
            By accessing or using the site and the services, the user agrees to
            be bound by these terms and conditions, including disclaimers. Saint
            Tale reserves the right to make changes to the site and these these
            these terms and conditions, including disclaimers, at any time. if
            you do not agree to the provisions of this agreement or are not
            satisfied with the service, your sole and exclusive remedy is to
            discontinue your use of the service.
          </p>

          <h1>Privacy Statement</h1>
          <p>
            Certain user information collected through the use of this website
            is automatically stored for reference. we track such information to
            perform internal research on our users&lsquo; demographic interests
            and behavior and to better understand, protect and serve our
            community of users. payment or any other financial information is
            never submitted, disclosed or stored on the site and is bound to
            terms and conditions and privacy policy of our respective partners
            and/or payment processors. basic user information (such as ip
            address, logs for using website interface and account management)
            may be disclosed to our partners in mutual efforts to counter
            potential illegal activities. Saint Tale makes significant effort to
            protect submitted information to prevent unauthorised access to that
            information in its internal procedures and technology. however, we
            do not guarantee, nor should you expect, that your personal
            information and private communications will always remain private.
          </p>
          {/* prettier-ignore-end */}
        </div>
      </div>
      <div className="space"></div>
    </div>
  );
};

export default Terms;
