/* Social Networks */

export const FACEBOOK_URL = 'https://www.facebook.com/SaintTale';
export const INSTAGRAM_URL = 'https://instagram.com/sainttaleofficial/';
export const YOUTUBE_URL = 'https://www.youtube.com/@sainttale';
export const DISCORD_URL = 'https://discord.gg/eqvXSCVQbw';

export const getUserPanelUrl = () => {
  return '/userpanel';
};
export const getRegisterUrl = () => {
  return '/userpanel?page=Login&action=CreateAccount';
};

/* Game Download */

export const MEDIAFIRE_URL = 'https://www.mediafire.com/file/soon';
export const GOOGLE_DRIVE_URL = 'https://drive.google.com/soon&export=download';
