import React from 'react';
import { Link } from 'react-router-dom';
import imageLogo from '../assets/img/logo.png';
import imageOrnament from '../assets/img/news/ornament2.png';

const ErrorPage = () => {
  return (
    <div className="page-content-full download-page error">
      <div className="page-content-header">
        <div className="logo">
          <img src={imageLogo}></img>
        </div>
        <h1>Saint Tale</h1>
        <h2>Are you ready to ascend?</h2>
        <div className="divider">
          <img src={imageOrnament}></img>
        </div>
        <p>Page not found.</p>
        <button className="teste">
          <Link to="/">Go to Home</Link>
        </button>
      </div>

      <div className="space"></div>
    </div>
  );
};

export default ErrorPage;
