/* eslint-disable no-undef */
export const gameClasses = [
  // Tempskrons
  {
    id: 1,
    job: 'Fighter',
    desc: 'Fighters only exist to fight and with their body so well trained, they can endure any kind of environment. They will enter close combat without fear and strike without mercy.',
    weapon: 'Axes',
    image: require('../../assets/img/sliders/fs.png'),
    poster: require('../../assets/img/class/fs.jpg'),
    video: require('../../assets/media/fs.mp4'),
  },
  {
    id: 2,
    job: 'Mechanician',
    desc: 'Mechanicians form Tempskrons heavy front-line in war. They are the only unmoved warriors who can stand truly strong against offensive machinery.',
    weapon: 'Hammers, Claws',
    image: require('../../assets/img/sliders/ms.png'),
    poster: require('../../assets/img/class/ms.jpg'),
    video: require('../../assets/media/ms.mp4'),
  },
  {
    id: 3,
    job: 'Archer',
    desc: 'Archers are excellent in long-range battle and anti-air attacks. Though they appear fragile, they possess the speed of the Falcon and the power of the Wolf.',
    weapon: 'Bows',
    image: require('../../assets/img/sliders/as.png'),
    poster: require('../../assets/img/class/as.jpg'),
    video: require('../../assets/media/as.mp4'),
  },
  {
    id: 4,
    job: 'Pikeman',
    desc: 'A Pikeman appears quickly and strikes with the rage ice and wind. Like a deadly storm they ravage the field, leaving no enemy unharmed. Their scythes are designed to kill.',
    weapon: 'Scythes',
    image: require('../../assets/img/sliders/ps.png'),
    poster: require('../../assets/img/class/ps.jpg'),
    video: require('../../assets/media/ps.mp4'),
  },
  {
    id: 5,
    job: 'Assassin',
    desc: 'Assassins are connoisseurs of stealth. Swiftly emerging from the shadows, they are able to finish their foes with a swing of their Daggers before their presence is even acknowledged.',
    weapon: 'Daggers',
    image: require('../../assets/img/sliders/ass.png'),
    poster: require('../../assets/img/class/ass.jpg'),
    video: require('../../assets/media/ass.mp4'),
  },

  // Morions
  {
    id: 6,
    job: 'Atalanta',
    desc: 'Atalantas are Morions amazons. The Javelin is their personalized weapon, which they enhance with their own magical power. They are excellent in long-ranged battles.',
    weapon: 'Javelins',
    image: require('../../assets/img/sliders/ata.png'),
    poster: require('../../assets/img/class/ata.jpg'),
    video: require('../../assets/media/ata.mp4'),
  },
  {
    id: 7,
    job: 'Knight',
    desc: 'Knights are honorable and stout warriors, who strike down every enemy in the name of the Gods. Their religious powers will devastate Mutants and the Undead.',
    weapon: 'Swords',
    image: require('../../assets/img/sliders/ks.png'),
    poster: require('../../assets/img/class/ks.jpg'),
    video: require('../../assets/media/ks.mp4'),
  },
  {
    id: 8,
    job: 'Magician',
    desc: 'Magicians are masters of the elements and apprentices of the universe. They possess devastating magical powers and the ability to mold natural energy into fatal attacks.',
    weapon: 'Staffs, Wands',
    image: require('../../assets/img/sliders/mgs.png'),
    poster: require('../../assets/img/class/mgs.jpg'),
    video: require('../../assets/media/mgs.mp4'),
  },
  {
    id: 9,
    job: 'Priestess',
    desc: 'Priestesses possess the blessed gift to strengthen the weak and heal the wounded. They may appear weak, but their mastery of magic should not be underestimated.',
    weapon: 'Staffs, Wands',
    image: require('../../assets/img/sliders/prs.png'),
    poster: require('../../assets/img/class/prs.jpg'),
    video: require('../../assets/media/prs.mp4'),
  },
  {
    id: 10,
    job: 'Shaman',
    desc: 'Shamans are spiritual lords of the occult. Through the use of Phantoms, they can access and invoke the realm of the dead and exploit the dark arts to cast powerful curses on their foes.',
    weapon: 'Phantoms',
    image: require('../../assets/img/sliders/sha.png'),
    poster: require('../../assets/img/class/sha.jpg'),
    video: require('../../assets/media/sha.mp4'),
  },
];
