// Until 140
export const experienceTable = [
  [0, 1000],
  [1000, 1500],
  [2500, 2500],
  [5000, 4500],
  [9500, 7600],
  [17100, 12825],
  [29925, 21546],
  [51471, 36029],
  [87500, 52501],
  [140001, 72800],
  [212801, 93633],
  [306434, 110316],
  [416750, 120858],
  [537608, 134402],
  [672010, 161283],
  [833293, 191657],
  [1024950, 225489],
  [1250439, 265094],
  [1515533, 309169],
  [1824702, 357641],
  [2182343, 410281],
  [2592624, 466672],
  [3059296, 532318],
  [3591614, 603391],
  [4195005, 679591],
  [4874596, 760437],
  [5635033, 845255],
  [6480288, 933161],
  [7413449, 1030470],
  [8443919, 1131485],
  [9575404, 1244803],
  [10820207, 1374166],
  [12194373, 1512102],
  [13706475, 1672190],
  [15378665, 1845440],
  [17224105, 2049669],
  [19273774, 2274305],
  [21548079, 2521125],
  [24069204, 2792028],
  [26861232, 3089042],
  [29950274, 3414331],
  [33364605, 3770200],
  [37134805, 4159099],
  [41293904, 4542329],
  [45836233, 5041986],
  [50878219, 5596604],
  [56474823, 6212231],
  [62687054, 6895576],
  [69582630, 7654089],
  [77236719, 8573276],
  [85809995, 9524909],
  [95334904, 10582175],
  [105917079, 11756796],
  [117673875, 13061800],
  [130735675, 14577027],
  [145312702, 16202367],
  [161515069, 18008930],
  [179523999, 20016926],
  [199540925, 22248813],
  [221789738, 24840451],
  [246630189, 27622581],
  [274252770, 30716310],
  [304969080, 34156537],
  [339125617, 37982070],
  [377107687, 42424615],
  [419532302, 47197383],
  [466729685, 52507090],
  [519236775, 58414137],
  [577650912, 64985728],
  [642636640, 72617941],
  [715254581, 80823767],
  [796078348, 89956854],
  [886035202, 100121977],
  [986157179, 111435762],
  [1097592941, 124576799],
  [1222169740, 138716265],
  [1360886005, 154460562],
  [1515346567, 171991835],
  [1687338402, 191512909],
  [1878851311, 225462157],
  [2104313468, 252517616],
  [2356831084, 282819731],
  [2639650815, 316758097],
  [2956408912, 354769070],
  [3311177982, 397341358],
  [3708519340, 445022321],
  [4153541661, 498424999],
  [4651966660, 558235999],
  [5210202659, 1042040532],
  [6252243191, 1250448638],
  [7502691829, 1500538366],
  [9003230195, 1800646039],
  [10803876234, 2160775247],
  [12964651481, 2592930297],
  [15557581778, 3111516356],
  [18669098134, 3733819626],
  [22402917760, 4480583553],
  [26883501313, 5376700262],
  [32260201575, 6452040315],
  [38712241890, 7742448378],
  [46454690268, 9290938054],
  [55745628322, 11149125665],
  [66894753987, 13378950797],
  [80273704784, 16054740957],
  [96328445741, 19265689149],
  [115594134890, 23118826978],
  [138712961868, 27742592373],
  [166455554241, 33291110849],
  [199746665090, 39949333018],
  [239695998108, 47939199621],
  [287635197729, 57527039546],
  [345162237275, 69032447455],
  [414194684730, 82838936946],
  [497033621676, 99406724336],
  [596440346012, 119288069202],
  [715728415214, 143145683043],
  [858874098257, 171774819651],
  [1030648917908, 206129783582],
  [1236778701490, 247355740298],
  [1484134441788, 296826888358],
  [1780961330146, 356192266029],
  [2137153596175, 427430719235],
  [2564584315410, 512916863082],
  [3077501178492, 615500235698],
  [3693001414190, 738600282838],
  [4431601697028, 886320339406],
  [5317922036434, 1063584407287],
  [6381506443721, 1276301288744],
  [7657807732465, 1531561546493],
  [9189369278958, 1837873855792],
  [11027243134750, 2205448626950],
  [13232691761700, 2646538352340],
  [15879230114040, 3175846022808],
  [19055076136848, 3811015227370],
  [22866091364218, 4573218272844],
  [27439309637062, 5487861927412],
  [32927171564474, 6585434312895],
  [39512605877369, 7902521175474],
  [47415127052843, 9483025410569],
  [56898152463412, 10810648968048],
];
